import React from "react";
import * as styles from "./index.module.scss";

import Helmet from "react-helmet";

import Footer from "@components/footer";
import Header from "@components/header";
import TextCenterBanner from "@components/textCenterBanner";

import phoneImg from "@src/images/agreement/Rectangle4386.png";
import pcImg from "@src/images/agreement/Rectangle4387.png";

import { Col, Collapse, Row } from "antd";

const renderDates = [
  {
    title: "1.儿童剥削：",
    detail:
      "您不得提供与非法利用或滥用儿童权益相关的商品和服务，也不得发布、上传任何此类材料，包括但不限于虐待儿童或性虐待，或者以性方式展示、介绍儿童的图片或描述。",
  },
  {
    title: "2.骚扰、欺凌、诽谤和威胁：",
    detail:
      "您不得提供骚扰、欺凌、诽谤或威胁特定个人的商品或服务，也不得发布、上传此类材料。",
  },
  {
    title: "3.恶意内容：",
    detail:
      "您不得通过使用我们的服务宣传或纵容针对种族、民族、肤色、国籍、宗教信仰、年龄、性别、性取向、残疾、健康状况或退伍军人身份进行的歧视和仇恨行为，也不得发布上传此类材料。您不得使用我们的服务来纵容或支持任何组织，平台或个人：（i）宣传或纵容此类仇恨； 或（ii）进一步宣传此类威胁或纵容此类暴力。",
  },
  {
    title: "4.非法活动：",
    detail:
      "您不得提供、发布或上传任何促成或宣传您所在司法管辖区的违法行为的商品或服务。",
  },
  {
    title: "5.知识产权：",
    detail:
      "您不得提供侵犯他人版权、商标权或其他知识产权的商品或服务，也不得发布上传此类材料。",
  },
  {
    title: "6.恶意和欺骗性的做法：",
    detail:
      "您不得使用Shoprise服务传播恶意软件或主机网络钓鱼页面。您不得进行损害或破坏Shoprise服务运营或其他Shoprise基础设施包括Shoprise第三方合作者的活动，也不得上传发布此类材料。您不得将本服务用于欺骗性的商业行为或其他任何非法或欺骗性行为。",
  },
  {
    title: "7.个人和机密信息：",
    detail:
      "您不得发布或上传任何包含个人身份信息，个人敏感信息或保密信息（如信用卡号码、保密国家身份证件号或账户密码）的材料，除非您已获得该信息所属权人的同意，或被授权提供该信息。",
  },
  {
    title: "8.自身伤害：",
    detail:
      "您不得提供或宣传与自我伤害相关的商品或服务，也不得发布上传此类材料。",
  },
  {
    title: "9.滥发邮件：",
    detail: "您不得使用本服务传输任何未经用户许可的商业电子信息。",
  },
  {
    title: "10.恐怖主义组织",
    detail:
      "您不得提供暗示、宣传或资助恐怖主义活动的商品或服务，也不得发布上传此类材料，或成为任何恐怖主义组织的成员。 如果您从事的活动违反了本《使用协议》（包括您在使用Shoprise服务之外的活动），我们有权随时删除任何材料，暂停或终止您的账户和您对我们服务的访问。 Shoprise有权随时监督或调查您使用的材料和Shoprise服务是否遵守本《使用协议》和Shoprise《 服务条款》，或任何其他与Shoprise达成的共识或协议（统称“协议”）。我们关于是否发生违反本《使用协议》行为的决定将是最终的和具有约束力的，我们自行决定如何处理违反本协议的行为（包括不采取任何行动）Shoprise若因Shoprise用户违反协议（“违约用户”）而致使Shoprise承担第三方赔偿责任的，Shoprise保留随时向违约用户追索赔偿补偿的权利；同时Shoprise保留要求该违约用户承担因此对Shoprise商誉损害的赔偿责任。 为给您提供更好的服务以及随着Shoprise业务的发展，本使用协议也会随之更新。《使用协议》修订版在生效前会发布公告或以其他适当方式提醒您相关内容的更新，也请您访问Shoprise网站以便及时了解最新的使用协议条款。 如果《使用协议》和《服务条款》发生冲突，以《使用协议》为准，但仅限于解决此类冲突所需的程度。在本协议中使用但未定义的术语应该按其所在协议中的含义定义。",
    date: ["最近更新日期：2021年05月31日", "生效日期：2021年05月31日"],
  },
];

const AgreementPage = () => {
  return (
    <div className={styles.agreementPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>用户协议-Shoprise 官网</title>
      </Helmet>
      <Header />
      <TextCenterBanner pcImg={pcImg} phoneImg={phoneImg} text="用户协议" />
      <div>
        <div className={styles.contentHeader}>
          Shoprise是一个为商家提供工具和技术，帮助他们建立自己的网上商店并在网站上销售产品和服务的商业平台。我们相信自由开放和交流是商业的一个关键原则，但是有一些活动与Shoprise的使命—创造更好的电商服务是不相容的。本《使用协议》描述了一些您使用我们服务时被禁止的活动。
          下文所讲的“材料”是指您的账户上传、收集、生成、存储、显示、发布、传输、展示或与您账户有关的任何照片、图像、视频、图形、书面内容、音频文件、代码、信息或数据。
          <br />
          <br />
          <br />
          以下活动是禁止的：
        </div>
        <Row>
          <Col xs={0} sm={0} md={24}>
            <div className={styles.contentMain}>
              {renderDates.map((item) => (
                <div className={styles.mainItem} key={item.title}>
                  <div className={styles.mainTitle}>{item.title}</div>
                  <div>{item.detail}</div>
                  {item.date && (
                    <div
                      style={{
                        float: "right",
                        marginTop: "64px",
                        marginBottom: "100px",
                      }}
                    >
                      {item.date.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Col>
          <Col xs={24} sm={24} md={0}>
            <Collapse
              // defaultActiveKey={["1"]}
              expandIconPosition="right"
              className={styles.phoneShowBox}
              style={{ marginTop: "30px" }}
              bordered={false}
            >
              {renderDates.map((item) => (
                <Collapse.Panel
                  header={
                    <div style={{ paddingLeft: "8px" }}>{item.title}</div>
                  }
                  key={item.title}
                >
                  <div className={styles.pannel}>
                    <div style={{ paddingLeft: "8px" }}>{item.detail}</div>
                    {item.date && (
                      <div className={styles.date}>
                        {item.date.map((item, index) => (
                          <div key={index}>{item}</div>
                        ))}
                      </div>
                    )}
                  </div>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  );
};
export default AgreementPage;
